@import "bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Single+Day&display=swap");

/* TODO: Remove unused @font-face declarations after design implementation */
/* metropolis-latin-100-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-100-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-100-normal.woff)
      format("woff");
}

/* metropolis-latin-200-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-200-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-200-normal.woff)
      format("woff");
}

/* metropolis-latin-300-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-300-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-300-normal.woff)
      format("woff");
}

/* metropolis-latin-400-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-400-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-400-normal.woff)
      format("woff");
}

/* metropolis-latin-500-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-500-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-500-normal.woff)
      format("woff");
}

/* metropolis-latin-600-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-600-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-600-normal.woff)
      format("woff");
}

/* metropolis-latin-700-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-700-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-700-normal.woff)
      format("woff");
}

/* metropolis-latin-800-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-800-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-800-normal.woff)
      format("woff");
}

/* metropolis-latin-900-normal */
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-900-normal.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-900-normal.woff)
      format("woff");
}

body {
  margin: 0;
  font-family: "Metropolis", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fffcf3;
}

.app {
  min-height: 100vh;
  background: #fffcf3;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 767px) {
    padding-bottom: 64px;
  }
}

.container {
  max-width: 1184px;
}

.page-header {
  color: #101212;
  font-weight: 700;
  text-align: center;
  font-size: 48px;

  @media screen and (max-width: 767px) {
    font-size: 28px;
    font-weight: 700;
    line-height: 36.4px;
  }
}

.page-description {
  font-family: "Metropolis";
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #6f6f6f;
  margin-bottom: 43px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 32px;
    line-height: 20.8px;
    font-weight: 400;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  padding: 12px;
  font-family: "Metropolis", sans-serif;
}

button.btn {
  margin: 1rem;
}

.list-item-hover {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-item-hover:hover {
  background-color: #fffbf9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Metropolis", "Montserrat", serif;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

.form-check .form-check-input {
  border-color: #fa8152;
}

.form-check-input:checked {
  background-color: #fa8152;
  border-color: #fa8152;
}

.processing-circle-wrapper {
  margin-top: 20vh;
}

.squiggly-lines.fade-out {
  opacity: 0;
}

.squiggly-lines {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.accordion {
  display: grid;
  grid-gap: 1rem;
}

.accordion-item {
  border-radius: 16px !important;
  overflow: hidden;
  border: 1px solid #c1c1c14d !important;
}

.accordion-item:hover {
  background: #fffbf9;
  @media screen and (max-width: 767px) {
    background: #fff;
  }
}

.accordion-active {
  background: #fffbf9;
  @media screen and (max-width: 767px) {
    background: #fff;
  }
}

.accordion-item.editor-tools {
  border-radius: 0.5rem !important;
}

.accordion-item.editor-tools .accordion-header button {
  height: 48px;
}

.accordion-header {
  padding: 0 16px;
}

.accordion-button {
  box-shadow: none !important;
  padding: 16px 4px;
  font-weight: 600;
  background-color: transparent;
}

.accordion-button.collapsed::after,
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff6d34'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button.accordionLocked.collapsed::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6.99805 14.5C6.99805 15.0525 7.44595 15.5004 7.99846 15.5004H16.0018C16.5543 15.5004 17.0022 15.0525 17.0022 14.5" stroke="%23FF6D34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.99805 14.4949V9.52574C6.99805 9.34127 7.09959 9.17178 7.26224 9.08474C7.42488 8.9977 7.62223 9.00724 7.77572 9.10957L9.9993 10.5919L11.584 8.21494C11.6767 8.07578 11.8329 7.99219 12.0002 7.99219C12.1674 7.99219 12.3236 8.07578 12.4164 8.21494L14.001 10.5919L16.2245 9.10955C16.378 9.00722 16.5754 8.99768 16.738 9.08473C16.9007 9.17177 17.0022 9.34127 17.0022 9.52574V14.4949" stroke="%23FF6D34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="12.0003" cy="11.9959" r="9.00375" stroke="%23FF6D34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-btn-color);
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.modal-transparent {
  background-color: transparent;
}
.modal-open {
  padding-right: 0 !important;
}
.modal-content {
  padding: 0;
  border-radius: 0;
  background-color: unset;
  border: unset;
  padding: 0;
}
.modal-dialog {
  background-color: unset;
  border: unset;
  padding: 0;
}
.modal-wide {
  width: 650px;
  max-width: calc(1184px - 20%);
}
.rdw-inline-wrapper,
.rdw-list-wrapper,
.rdw-block-wrapper {
  border-left: 1px solid #f1f1f1;
  padding: 0 8px;
}

.rdw-option-wrapper {
  min-width: 42px;
  height: 38px;
  border: unset;
}

table,
tr,
td,
th {
  border: 1px solid #555;
}

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown a:hover {
  background-color: #ddd;
}

.toast-out {
  display: none;
}

#root {
  --toastify-toast-min-height: 44px;
}

@keyframes dummy {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.dummy {
  animation: dummy 0.6s;
}
.Toastify__toast-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px !important;
}
.Toastify__toast-container {
  width: auto;
  min-width: var(--toastify-toast-width);
  @media screen and (max-width: 767px) {
    top: 5px;
    left: 10px;
    right: 10px;
  }
}
.Toastify__toast {
  border-radius: 10px !important;
  padding: 16px 32px !important;
  color: var(--Additional-Red, #F12A2A);
  text-align: start;
  /* Body 14 Medium */
  font-family: Metropolis, Montserrat !important;
  min-width: 432px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 130% !important;
  letter-spacing: -0.308px !important;
  color: #082249;
  align-items: center;
}
.notificationCrossIcon {
  align-self: center;
}
.Toastify__toast-icon {
  margin-inline-end: 20px;
}
.Toastify__toast--success {
  background-color: #cce7dc;
}
.Toastify__toast--error {
  background-color: #FFE2E2 !important;
}
.Toastify__toast-body {
  padding: 0 !important;
}
.Toastify__zoom-enter {
  animation-name: none;
}